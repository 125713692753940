
/**
 * We wanted the steps to be rendered as child components of one view
 * this home will help us setup or ...
 */
import { quoteMapActions, quoteMapState } from "@/store/modules/quote";
import Vue from "vue";

interface Idata {
  didInit: boolean;
  activeIndex: string;
  error: string;
  message: string;
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
}

export default Vue.extend({
  name: "quoting-home",
  components: {},
  props: {
    quoteId: {
      type: String,
      required: true
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.matched.length === 1) {
      return next(`${to.path}/risk-address`);
    }
    // WE MAY HAVE TO RE-ROUTE AS PREFERRED
    next();
  },
  data(): Idata {
    return {
      didInit: false,
      activeIndex: "info",
      error: "",
      message: "",
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The quote you are looking for cannot be found."
    };
  },
  created: function() {
    this.initializePageData();
  },
  destroyed() {
    //destroy editing quote
  },
  methods: {
    ...quoteMapActions(["getQuote"]),
    async initializePageData(): Promise<void> {
      try {
        await this.getQuote(this.quoteId);
        // this.processRedirection();
        this.didInit = true;
      } catch (error) {
        this.resourceNotFound = true;
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    processRedirection() {
      const {
        ratingValidations,
        _id,
        hasRiskAddress,
        hasTerritoryInformation,
        status
      } = this.editing;
      const ratingsStatus = ["rating-in-progress", "rating-completed"];
      if (ratingsStatus.includes(status)) {
        return this.$router.push(`/quotes/${_id}/property-ratings`);
      }
      if (ratingValidations && ratingValidations.canRateQuote) {
        return this.$router.push(`/quotes/${_id}/property-rating`);
      }
      if (!hasTerritoryInformation) {
        return this.$router.push(`/quotes/${_id}/territory`);
      }
      if (!hasRiskAddress) {
        return this.$router.push(`/quotes/${_id}/risk-address`);
      }
    }
  },
  computed: {
    ...quoteMapState(["makingApiRequest", "editing"])
  }
});
